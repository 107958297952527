
// axios
import axios from 'axios'

//smooth scroll polyfill
import smoothscroll from "smoothscroll-polyfill";

//custom components
import FlipCard from "@/components/FlipCard.vue";

import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    FlipCard
  },
  data() {
    return {
      config: [],
      flipCard: false,
      inputsCount: 0,
      currentIndex: 0,
      isProcessing: false
    }
  },
  mounted() {
    //set config
    this.config = JSON.parse( localStorage.getItem('config') as any[any])

    // set server url
    this.serverUrl = this.config.paymentPostEndpoint

    //set smooth scroll
    smoothscroll.polyfill();

    // select all inputs
    var inputs = document.querySelectorAll("input");

    if(inputs.length > 0){
      // set inputs total count
      this.inputsCount = inputs.length

      // focus first input
      inputs[0].focus();

      // add events for each input
      inputs.forEach((input) => {
        input.addEventListener("keydown", (event) => {
          let name = input.getAttribute("name") as string;
          let oldValue = input.value;
          let newValue = '';

          // get new value
          if (event.key !== "Backspace" && event.key.length === 1) {
            if (name == "cardNumber") {
              newValue = this.cc_format(oldValue)
            } else if (name == "cardExp") {
              newValue = this.exp_format(oldValue)
            } else if (oldValue.length === 0) {
              newValue = event.key
            }
          }

          // disable backspace
          if (event.key === "Backspace") {
              newValue = oldValue
          }

          //update current input
          if (newValue.length > 1) {
            input.value = newValue
          }
        });

        input.addEventListener("focus", () => {
          input.classList.add("active")
        });
      });
    }
  },
  methods: {
    cc_format: function (value: string) {
      var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "")
      var matches = v.match(/\d{4,16}/g)
      var match = (matches && matches[0]) || ""
      var parts = []

      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
      }

      if (parts.length) {
        return parts.join(" ")
      } else {
        return value
      }
    },
    exp_format: function (value: string) {

      var v = value.replace(/\s+/g, "").replace(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})/gi, "")
      var matches = v.match(/\d{2,4}/g);
      var match = (matches && matches[0]) || ""
      var parts = [];

      for (let i = 0, len = match.length; i < len; i += 2) {
        parts.push(match.substring(i, i +2))
      }

      if (parts.length) {
        return parts.join("/")
      } else {
        return value;
      }
    },
    changeField: function (direction: string) {
      // select all inputs
      var inputs = document.querySelectorAll("input")

      //get current input
      const input = inputs[this.currentIndex]

      // get form and form width
      const form = this.$refs.form;
      const formW = form.offsetWidth;

      // old position
      let oldPos = form.scrollLeft;

      // new position
      let newPos = oldPos;

      // can procees flag
      let canProceed = true;

      //get input min lenght
      const minLenght = Number(input.getAttribute("minlength"));

      // check if current input is empty
      if (
        (input.value.length === 0 || input.value.length < minLenght) &&
        direction == "next"
      ) {
        canProceed = false;
        input.classList.add("border", "border-red-600");
      }

      if (
        direction == "next" &&
        canProceed &&
        this.currentIndex >= 0 &&
        this.currentIndex < inputs.length - 1
      ) {
        this.currentIndex++;
        newPos = oldPos + formW;
        
        // flip card on cvv
        if(inputs[this.currentIndex].name == "cardCvv"){
          this.flipCard = true
        }else{
          this.flipCard = false
        }
      }

      if (
        direction == "prev" &&
        canProceed &&
        this.currentIndex >= 0 &&
        this.currentIndex < inputs.length
      ) {
        this.currentIndex--;
        inputs[this.currentIndex].focus()
        newPos = oldPos - formW;
        input.classList.remove("active")
      }

      if (canProceed) {
        form.scroll({
          top: 0,
          left: newPos,
          behavior: "smooth",
        });

        inputs[this.currentIndex].focus();
      }
    },
    getToken: async function(){
      
      //set is proccessing flag
      this.isProcessing = true
    
      // decidir
      const publicApiKey = this.config.prodPublicKey
      const url = 'https://live.decidir.com/api/v2'
      const decidir = new (window as any).Decidir(url, true)
      decidir.setPublishableKey(publicApiKey)
      decidir.setTimeout(5000)

      // get form
      const form = this.$refs.form

      // get card number
      const cardNumber = form.querySelector('[name=cardNumber]').value

      // get exp date and split
      const cardExp = form.querySelector('[name=cardExp]').value.split('/')
      form.querySelector('[name=cardExp]')

      // add exp month
      let month = document.createElement("input");
      month.type = "number"
      month.name = "expMonth"
      month.value = cardExp[0]
      month.setAttribute('data-decidir', 'card_expiration_month')
      form.appendChild(month);

      // add exp year
      let year = document.createElement("input");
      year.type = "number"
      year.name = "expYear"
      year.value = cardExp[1]
      year.setAttribute('data-decidir', 'card_expiration_year')
      form.appendChild(year);

      // add card holder identification
      let cardHolderType = document.createElement("input");
      cardHolderType.type = 'text'
      cardHolderType.name = 'cardHolderType'
      cardHolderType.value = 'DNI'
      cardHolderType.setAttribute('data-decidir', 'card_holder_doc_type')
      form.appendChild(cardHolderType);

      // response handler
      const sdkResponseHandler = (status: any, response: any) => {
        if (status !== 200 && status !== 201) {
          this.$router.push('/error')
        } else {
          // add issuer
          response.issuer = decidir.cardType(cardNumber)

          // process payment
          this.processPayment(response)
        }
      }

      //send
      decidir.createToken(form, sdkResponseHandler)
    },
    processPayment: async function (tokenData: any) {

      // set payload
      const payload = {
        orderId: this.$route.query.id,
        id: tokenData.id,
        pm: tokenData.issuer,
        pmid: this.$route.query.pmid,
        bin: tokenData.bin
      }

      // send to backend
      await axios({
        method: 'post',
        url: this.serverUrl,
        headers: {}, 
        data: payload
      })
      .then((response: any)=>{
        console.log(response)
        if(response.data.status === 'approved'){
          this.$router.push('/success')
        }else{
          this.$router.push('/error')
        }
      })
      .catch((error)=>{
        console.log(error)
        this.$router.push('/error')
      })
    },
  },
})
export default class AddCard extends Vue {}
